$(document).ready(function () {
	$('.addtoany_shortcode').each(function() {
		$(this).closest('.post-card__close-button').append(this);
	});
});

$('.post-card__close-button').each(function(){
	$(this).click(function(event){
		event.preventDefault();
		$(this).closest('.post-card__share--tooltip').animate({
			width: 0
		}, 500)
	})
})

$('.post-card__share-button').each(function(){
	$(this).click(function(event){
		event.preventDefault();
		$(this).closest('.post-card__share').find('.post-card__share--tooltip__container').animate({
			width: '197px',
		}, 1000)
		$(this).closest('.post-card__share').find('.post-card__share--tooltip').animate({
			width: '197px',
		}, 500)
	});
})