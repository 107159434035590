import "jquery";
import "../styles/style.scss";
import "./pages/blog";
import "mmenu-js";

document.addEventListener("DOMContentLoaded", () => {
  new Mmenu(
    "#navigation-menu__mobile",
    {
      scrollBugFix: {
        fix: false
      },
      navbars: [
        {
          position: "top",
          content: ["close"]
        }
      ],
      extensions: ["fx-panels-none", "position-front"],
      hooks: {
        "openPanel:start": (panel) => {
          var fullyOpened = document.getElementsByClassName("fully-opened");
          // console.log(fullyOpened)
          if (fullyOpened.length > 0) {
            console.log("fully opened items");
            for (const openedPanel of fullyOpened) {
              openedPanel.classList.remove("fully-opened");
            }
          }
        },
        "openPanel:finish": (panel) => {
          panel.classList.add("fully-opened");
        }
      }
    },
    {
      openingInterval: 0,
      transitionDuration: 0,
      panelNodeType: ["ul"]
    }
  );

  new Swiper(".mySwiper", {
    direction: "vertical",
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false
    }
  });
});

import { library, dom, config } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookSquare,
  faInstagram,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";
import {
  faSearch,
  faCaretDown,
  faXmark,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFacebookSquare,
  faSearch,
  faCaretDown,
  faChevronRight,
  faXmark,
  faInstagram,
  faTiktok
);
dom.watch();

config.searchPseudoElements = true;
console.log(config);

$(function () {
  $(".button__menu").click(function () {
    $("#navigation-menu").slicknav("toggle");
    $(this).toggleClass("menu-open");
  });
});

window.onload = (event) => {
  var element = document.getElementById("app");
  element.classList.add("all-loaded");
};

$(document).ready(function () {
  $(".mm-navbar.mm-navbar_sticky").remove();

  $(".mm-btn.mm-btn_close")
    .first()
    .append(`<i class="fas fa-xmark" style="width: 21px; height: 21px">`);

  $(".mm-listitem__text")
    .first()
    .append(
      `<i class="fas fa-chevron-right" style="width: 21px; height: 21px">`
    );

  $("#search_toggle").click(function () {
    $(".search-form--header").css("width", "100%");

    $("#search_form").toggleClass("hidden");
    $(this).toggleClass("hidden");

    $("#search_field").focus();
  });

  $("#search_field").on("blur", function () {
    $(".search-form--header").css("width", "auto");

    $("#search_form").toggleClass("hidden");
    $("#search_toggle").toggleClass("hidden");
  });

  $("#mm-1").append(
    '<a href="' +
      (window.location.pathname.startsWith("/staging")
        ? window.location.origin + "/staging"
        : window.location.origin) +
      '/our-values/" class="button values-button">OUR VALUES</a>'
  );
});
